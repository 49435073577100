<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="40%"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true"
  >
    <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}增值税类型</div>

    <div class="content" v-loading="loading" v-if="dialogType == 'second'">
      <el-form label-width="9em" :model="form" :rules="rules" ref="form">
        <el-form-item label="类型名称">
          <el-input disabled v-model="form.parentName"></el-input>
        </el-form-item>
        <el-form-item label="明细名称" prop="vatName">
          <el-input
            v-model="form.vatName"
            placeholder="请输入类型明细名称"
            @change="nameChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="明细代码" prop="vatData">
          <el-input v-model="form.vatData" placeholder="请输入类型明细代码"></el-input>
        </el-form-item>
        <el-form-item label="排序号（升序）" prop="sort">
          <el-input
            v-model="form.sort"
            type="number"
            class="numrule"
            placeholder="请输入字典排序号"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="content" v-loading="loading" v-else-if="dialogType == 'third'">
      <el-form label-width="7em" :model="form" :rules="rules" ref="form">
        <el-form-item label="类型名称">
          <el-input disabled v-model="form.parentName"></el-input>
        </el-form-item>
        <el-form-item label="明细名称">
          <el-input disabled v-model="form.vatName"></el-input>
        </el-form-item>
        <el-form-item label="税率" prop="vatRate">
          <el-input
            v-model="form.vatRate"
            placeholder="请输入类型税率"
            oninput="if(!/^[0-9]+$/.test(value)); value=value.replace(/\D/g,'');if(value>100)value=100;if(value<0)value=null"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="content" v-loading="loading" v-else>
      <el-form label-width="9em" :model="form" :rules="rules" ref="form">
        <el-form-item label="类型名称" prop="vatName">
          <el-input
            v-model="form.vatName"
            placeholder="请输入增值税类型名称"
            @change="nameChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型代码" prop="vatType">
          <el-input
            v-model="form.vatType"
            @input="$forceUpdate()"
            placeholder="请输入增值税类型代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序号（升序）" prop="sort">
          <el-input
            v-model="form.sort"
            type="number"
            class="numrule"
            placeholder="请输入字典排序号"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" v-show="!loading">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    dialogType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      rules: {
        vatName: [{ required: true, message: '请输入名称', trigger: ['blur', 'change'] }],
        vatType: [{ required: true, message: '请输入增值税类型代码', trigger: ['blur', 'change'] }],
        vatData: [{ required: true, message: '请输入增值税明细代码', trigger: ['blur', 'change'] }],
        vatRate: [{ required: true, message: '请输入增值税税率', trigger: ['blur', 'change'] }],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.$emit('update:loading', false);
        this.showEditDialog = newVal
        if (this.dialogType == 'second') {
          this.form = {
            vatData: '',
            vatName: '',
            sort: '',
            remark: '',
          }
        } else if (this.dialogType == 'third') {
          this.form = {
            vatRate: '',
            remark: '',
          }
        } else {
          this.form = {
            vatName: '',
            vatType: '',
            sort: '',
            remark: '',
          }
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.form = newVal
        this.loading = false
      },
    },
  },
  created() {},
  methods: {
    nameChange(e) {
      if (this.dialogType == 'second') {
        if (!this.form.vatData) {
          this.form.vatData = e.getPinyin('_')
        }
      } else {
        if (!this.form.vatType) {
          this.form.vatType = e.getPinyin('_')
        }
      }
      this.$forceUpdate()
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          if (this.dialogType == '') {
            // 修改的是增值税类型弹框
            if (this.form.id) {
              this.$api.vat
                .editVatType(this.form)
                .then(res => {
                  this.submitLoading = false
                  this.refresh()
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err)
                })
            } else {
              this.$api.vat
                .addVatType(this.form)
                .then(res => {
                  this.submitLoading = false
                  this.refresh()
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err)
                })
            }
          } else if (this.dialogType == 'second') {
            // 修改的是增值税数据
            if (this.form.id) {
              this.$api.vat
                .editVatData(this.form)
                .then(res => {
                  this.submitLoading = false
                  this.refresh()
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err)
                })
            } else {
              this.$api.vat
                .addVatData(this.form)
                .then(res => {
                  this.submitLoading = false
                  this.refresh()
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err)
                })
            }
          } else if (this.dialogType == 'third') {
            // 修改的是增值税数据
            if (this.form.id) {
              this.$api.vat
                .editVatRate(this.form)
                .then(res => {
                  this.submitLoading = false
                  this.refresh()
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err)
                })
            } else {
              this.$api.vat
                .addVatRate(this.form)
                .then(res => {
                  this.submitLoading = false
                  this.refresh()
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err)
                })
            }
          }
        } else {
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
